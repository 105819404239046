import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Cms, INavigationResponse, TempAny } from '@impact/data';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';

import { chevronRight } from '../../../icons';
import { mobileAnimation, MobileAnimationState } from '../../utils/animations/mobile-menu.animations';

@Component({
    selector: 'app-mobile-articles-nav',
    template: `
        <div [@slideContent]="animationState">
            <div class="mobile-menu__section-header">
                <h1>{{ navigation.name }}</h1>
            </div>

            <div class="mobile-menu__scroll-container mobile-menu__scroll-container--sub-header">
                <ul class="mobile-menu__list">
                    <li class="mobile-menu-item">
                        <a [routerLink]="navigation?.url" (click)="closeMenu()">
                            <span class="mobile-menu-item__text">
                                {{ 'articles.show_all_articles_link_text' | translate }}
                            </span>
                            <i class="mobile-menu-item__icon--nav">${chevronRight}</i>
                        </a>
                    </li>
                </ul>

                <div class="mobile-menu__heading">{{ navigation?.articlesNavigationData?.articleCategoriesTagNavigationHeader }}</div>
                <ul class="mobile-menu__list">
                    <li *ngFor="let tag of navigation?.articlesNavigationData?.articleCategoriesTagNavigation" class="mobile-menu-item">
                        <a [routerLink]="navigation?.url" [queryParams]="tag?.param" (click)="closeMenu()">
                            <span class="mobile-menu-item__text">{{ tag?.displayName }}</span>
                            <i class="mobile-menu-item__icon--nav">${chevronRight}</i>
                        </a>
                    </li>
                </ul>

                <div class="mobile-menu__heading">{{ navigation?.articlesNavigationData?.vehicleMakesTagsHeadline }}</div>
                <ul class="mobile-menu__list">
                    <li *ngFor="let tag of navigation?.articlesNavigationData?.vehicleMakesTagsNavigation" class="mobile-menu-item">
                        <a [routerLink]="navigation?.url" [queryParams]="tag?.param" (click)="closeMenu()">
                            <span class="mobile-menu-item__text">{{ tag?.displayName }}</span>
                            <i class="mobile-menu-item__icon--nav">${chevronRight}</i>
                        </a>
                    </li>
                </ul>

                <!-- NAVIGATION SPOT -->
                <div
                    class="mobile-menu__spot-wrapper"
                    *ngIf="
                        navigation.megaNavigationSpot | isValidNavigationSpot
                    "
                >
                    <app-mega-navigation-spot
                        [data]="navigation.megaNavigationSpot"
                    ></app-mega-navigation-spot>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [mobileAnimation],
})
export class MobileArticlesNavComponent {
    @Input() navigation: Cms.NavigationDto;


    animationState: MobileAnimationState = 'enter';

    constructor(private overlayRef: ImpactOverlayRef) {}

    closeMenu() {
        this.overlayRef.close();
    }
}
