import { ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { Cms, INavigationResponse, TempAny } from '@impact/data';
import { ImpactOverlayRef } from '@impactdk/ngx-overlay';
import * as icons from '../../../icons';
import { mobileAnimation, MobileAnimationState } from '../../utils/animations/mobile-menu.animations';
import { MobileMenuTypes } from './mobile-menu';
import { MobileNavigationService } from './mobile-navigation.service';


@Component({
    selector: 'app-mobile-used-cars-nav',
    template: `
        <div [@slideContent]="animationState">
            <div class="mobile-menu__section-header">
                <h1>{{ navigation.name }}</h1>
            </div>

            <div class="mobile-menu__scroll-container mobile-menu__scroll-container--sub-header">
                <ul class="mobile-menu__list">
                    <li *ngFor="let item of navigation.children" class="mobile-menu-item">
                        <app-mobile-menu-link-item [item]="item" (btnClick)="navigate(item)"></app-mobile-menu-link-item>
                    </li>
                </ul>
                <!-- NAVIGATION SPOT -->
                <div
                    class="mobile-menu__spot-wrapper"
                    *ngIf="
                        navigation.megaNavigationSpot | isValidNavigationSpot
                    "
                >
                    <app-mega-navigation-spot
                        [data]="navigation.megaNavigationSpot"
                    ></app-mega-navigation-spot>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [mobileAnimation],
})
export class MobileUsedCarsNavComponent implements OnInit {
    @Input() navigation: TempAny; // TODO: Cms.NavigationDto;

    linkPagesToShow: Cms.NavigationDto[] = [];

    icons = icons;
    MobileMenuTypes = MobileMenuTypes;

    animationState: MobileAnimationState = 'enter';

    constructor(public overlayRef: ImpactOverlayRef, public mobileNavigationService: MobileNavigationService) {}

    ngOnInit(): void {
        this.linkPagesToShow = this.navigation.children?.filter((navigationChild: TempAny) => !navigationChild.hideInMenu) || [];
    }
    navigate(item: Cms.NavigationDto) {
        const isUsedCarsContext = this.navigation.template === 'usedCarsOverviewPage' || this.navigation.template === 'vehicleLeasingOverviewPage';
        this.mobileNavigationService.navigateByObject({
            type: isUsedCarsContext ? MobileMenuTypes.BrandsOverview : MobileMenuTypes.NewCarsBrandModels,
            navigation: item,
            brandNodeId: item.id,
        });
    }
}
