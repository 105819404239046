import { Pipe, PipeTransform } from '@angular/core';
import { Cms } from '@impact/data';

export function isValidNavigationSpot(spot: Cms.MegaNavigationSpotDto | null) {
    return !!spot?.headline || !!spot?.text;
}

@Pipe({
    name: 'isValidNavigationSpot',
})
export class IsValidNavigationSpotPipe implements PipeTransform {
    transform(spot: Cms.MegaNavigationSpotDto | null) {
        return isValidNavigationSpot(spot);
    }
}
