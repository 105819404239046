import { Component, Input, OnInit } from '@angular/core';
import { Cms } from '@impact/data';

@Component({
    selector: 'app-mega-navigation-spot',
    templateUrl: './mega-navigation-spot.component.html',
})
export class MegaNavigationSpotComponent implements OnInit {
    @Input() data: Cms.MegaNavigationSpotDto;

    hasImage = false;
    hasLink = false;

    ngOnInit() {
        this.hasImage = !!this.data.image?.src;
        this.hasLink = !!this.data.link?.url;
    }
}
