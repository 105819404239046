import { Pipe, PipeTransform } from '@angular/core';

export function asCssImageUrl(url: string | undefined) {
    return url ? `url(${url})` : undefined;
}

@Pipe({
    name: 'asCssImageUrl',
})
export class AsCssImageUrlPipe implements PipeTransform {
    transform(url: string | undefined) {
        return asCssImageUrl(url);
    }
}
