import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    Cms,
    PAGE_TYPES,
    TempAny
} from '@impact/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { chevronRight, searchIcon } from '../../../icons';
import { SettingsService } from '../../core/settings.service';
import { Environment, ENVIRONMENT } from '../../environment/environment';
import { NavigationService } from '../navigation.service';
import { megaNavigationColumnsMaxSize } from './mega-navigation.component';

@Component({
    selector: 'app-mega-navigation-used-cars',
    template: `
        <div class="mega-navigation-layout-wrapper">
            <div class="mega-navigation-layout-wrapper-inner">
                <div class="mega-navigation__content-header" [ngClass]="{'is-ready': isReady}">
                    <h2 class="mega-navigation-spot-layout__content-header-text">{{ data?.name }}</h2>
                    <ng-container [ngSwitch]="isB2B$ | async">
                        <ng-container *ngSwitchCase="true">
                            <a [routerLink]="b2bUsedCarsOverviewPageUrl" class="button--primary" *ngIf="b2bUsedCarsOverviewPageUrl">
                                <span>{{ 'buttons.search_all_used_cars_text' | translate }}</span>
                                <i class="button__icon">${searchIcon}</i>
                            </a>
                        </ng-container>
                        <ng-container *ngSwitchCase="false">
                            <a [ngSwitch]="site" [routerLink]="usedCarsOverviewPageUrl" class="button--primary">
                                <ng-container *ngSwitchCase="'Motorpoint'">
                                    <span>{{ 'buttons.mp_show_all_cars' | translate }}</span>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <span>{{ 'buttons.search_all_used_cars_text' | translate }}</span>
                                </ng-container>
                                <i class="button__icon">${searchIcon}</i>
                            </a>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="mega-navigation-content-layout" [ngClass]="{'is-ready': isReady}">
                    <div class="mega-navigation__content">
                        <!-- LEFT COLUMN -->
                        <div class="mega-navigation__content-column-left">
                            <ul class="mega-navigation__navigation-list mega-navigation__navigation-list--secondary" *ngIf="pagesNavigation">
                                <ng-container *ngFor="let page of pagesNavigation">
                                    <li [ngSwitch]="page.hasChildren" class="mega-navigation__navigation-list-item">
                                        <ng-container *ngSwitchCase="true">
                                            <button type="button" class="mega-navigation__navigation-list-link"
                                                [ngClass]="{'is-active': activeNavigationCategory === page.id}"
                                                (click)="showChildPages(page.id)"
                                                (mouseover)="showChildPages(page.id)">
                                                {{page?.name}}
                                            </button>
                                            <div class="mega-navigation__navigation-active-indicator">${chevronRight}</div>
                                        </ng-container>
                                        <a *ngSwitchCase="false"
                                           [routerLink]="page?.url"
                                           class="mega-navigation__navigation-list-link"
                                           (mouseover)="showChildPages('')">{{page.name}}</a>
                                     </li>
                                </ng-container>
                            </ul>
                        </div>

                        <!-- RIGHT COLUMN -->
                        <div class="mega-navigation__content-column-right">
                            <ng-container *ngTemplateOutlet="PageLinks; context: { items: activePagesNavigation }"></ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <!-- MEGA NAVIGATION SPOT -->
            <div
                class="mega-navigation__spot-wrapper"
                *ngIf="navigationSpot | isValidNavigationSpot"
            >
                <app-mega-navigation-spot
                    [data]="navigationSpot"
                ></app-mega-navigation-spot>
            </div>
        </div>

        <!-- TEMPLATES -->
        <ng-template #Level1Button let-text="text" let-category="category">
            <button type="button" class="mega-navigation__navigation-list-link"
                [ngClass]="{'is-active': activeNavigationCategory === category}"
                (click)="setActiveCategory(category)"
                (mouseover)="setActiveCategory(category)">
                {{text}}
            </button>
            <div class="mega-navigation__navigation-active-indicator">${chevronRight}</div>
        </ng-template>

        <ng-template #CategoryLinks let-items="items">
            <div class="mega-navigation__scroll-container">
                <ul class="mega-navigation-spot-layout__content-list"
                    [ngClass]="{
                        'double-column': items.length > columnItemsSize,
                        'triple-column': items.length > columnItemsSize * 2
                    }">
                    <li *ngFor="let link of items">
                        <a [routerLink]="link?.link?.url" [queryParams]="link?.link?.params">{{link?.linkText}}</a>
                    </li>
                </ul>
            </div>
        </ng-template>

        <!-- Pages link -->
        <ng-template #PageLinks let-items="items">
            <div class="mega-navigation__scroll-container">
                <ul class="mega-navigation-spot-layout__content-list"
                    [ngClass]="{
                        'double-column': items.length > columnItemsSize,
                        'triple-column': items.length > columnItemsSize * 2
                    }">
                    <li *ngFor="let page of items" [ngSwitch]="page.template === pageTypes.LINK_PAGE">
                        <a *ngSwitchCase="true" [routerLink]="page?.pageLink?.url" [queryParams]="page?.pageLink?.params">{{page?.name}}</a>
                        <a *ngSwitchCase="false" [routerLink]="page?.url" [queryParams]="page?.params">{{page?.name}}</a>
                    </li>
                </ul>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MegaNavigationUsedCarsComponent implements OnInit, OnDestroy {
    private unsubscribe = new Subject<void>();

    @Input() data: TempAny; // TODO: Cms.NavigationDto;

    navigationSpot: TempAny; // TODO: Cms.MegaNavigationSpot;
    pagesNavigation: Cms.NavigationDto[] = [];
    activeNavigationCategory: string;
    activePagesNavigation: Cms.NavigationDto[] = [];
    columnItemsSize = megaNavigationColumnsMaxSize;
    site = this.environment.site;
    usedCarsOverviewPageUrl: string;
    b2bUsedCarsOverviewPageUrl: string | undefined;
    isReady: boolean;
    isB2B$ = this.navigationService.isB2B$;
    pageTypes = PAGE_TYPES;

    constructor(
        private cdr: ChangeDetectorRef,
        private navigationService: NavigationService,
        private settingsService: SettingsService,
        @Inject(ENVIRONMENT) private environment: Environment,
    ) {}

    ngOnInit() {
        this.navigationSpot = this.data.megaNavigationSpot;

        if (this.data?.children) {
            this.data?.children.map((page: TempAny) => {
                if (page.includeInNavigation) {
                    this.pagesNavigation.push({
                        ...page,
                        hasChildren: Boolean(page.children && page.children.length)
                    });
                }
            });
        }

        // Structuring navigation content
        this.settingsService.get()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.usedCarsOverviewPageUrl = settings.globalPages?.usedCarsOverviewPage?.url;
                this.b2bUsedCarsOverviewPageUrl = settings.globalPages?.b2bUsedCarsOverviewPage ? settings.globalPages.b2bUsedCarsOverviewPage?.url + '/bilmaerker' : undefined;
                this.showChildPages(this.pagesNavigation[0].id);

                setTimeout(() => {
                    this.isReady = true;
                    this.cdr.markForCheck();
                }, 350);

                this.cdr.markForCheck();
            });
    }

    setActiveCategory(category: string) {
        this.activeNavigationCategory = category;
    }

    showChildPages(id: string): void {
        // Reset values
        if (id === '') {
            this.activeNavigationCategory = '';
            this.activePagesNavigation = [];
            return;
        }

        this.activeNavigationCategory = id;

        for (const page of this.pagesNavigation) {
            if (page.id === id) {
                this.activePagesNavigation = page.children || [];
                break;
            }
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
