import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { CarouselSpotItem } from '@impact/data';
import SwiperCore, { Autoplay, Lazy, Navigation, Pagination } from 'swiper/core';
import { AutoplayOptions, LazyOptions } from 'swiper/types';
import { MetaService } from '../meta/meta.service';
import { FeatureDetectionService } from '../utils/helpers/feature-detection.service';
import { mediaIsImage } from '../utils/helpers/image-helper';
import { MediaSliderService } from './media-slider.service';
import { ImageProcessorOptions, isImage } from '../utils/helpers/media-helpers';

SwiperCore.use([Autoplay, Navigation, Pagination, Lazy]);
@Component({
    selector: 'app-media-slider-new',
    template: `
        <div class="media-slider-new" [ngClass]="{ 'slider-active': activateSlider }">
            <div class="media-slider__slider-wrapper">
                <swiper
                    class="media-slider__swiper"
                    (slideChange)="onSlideChange($event)"
                    [index]="activeIndex"
                    [allowTouchMove]="activateSlider"
                    [autoplay]="setAutoPlay"
                    [preloadImages]="true"
                    [lazy]="lazyOptions"
                    [navigation]="activateSlider"
                    [pagination]="activateSlider"
                >
                    <ng-template swiperSlide *ngFor="let item of sliderItems; let i = index;">
                        <app-media-item
                            [item]="item.media"
                            [vimeoId]="item.vimeoVideoID"
                            [videoFallbackImage]="item?.videoFallbackImage"
                            [lazyLoad]="i === 0 ? false : lazyLoad"
                        ></app-media-item>
                        <div class="media-slider__image-overlay"></div>

                        <div class="media-slider__text-content">
                            <app-rich-text
                                class="media-slider__rich-text"
                                [class.media-slider__text-content--aligned-left]="item?.alignTextLeft"
                                [preLoadImages]="preLoadRTEImages"
                                [html]="item?.richText">
                            </app-rich-text>
                        </div>
                    </ng-template>
                </swiper>

                <div class="media-slider__thumbnails" *ngIf="sliderItems.length > 1">
                    <button
                        class="button--minimal media-slider__thumbnails-item"
                        *ngFor="let item of sliderItems; index as i"
                        [ngClass]="{ 'media-slider__thumbnails-item--active': activeIndex === i }"
                        [ngSwitch]="item.media?.src | isImageUrl"
                        (click)="setActiveIndex(i)"
                    >
                        <img
                            class="media-slider__thumbnails-item-image"
                            *ngSwitchCase="true"
                            [attr.src]="item.media?.src | resolveMediaUrl : imageProcessorOptions"
                            [attr.alt]="item.media?.alt"
                        />
                        <img
                            class="media-slider__thumbnails-item-image"
                            *ngSwitchCase="false"
                            [attr.src]="item.videoFallbackImage?.src | resolveMediaUrl : imageProcessorOptions"
                            [attr.alt]="item.videoFallbackImage?.alt"
                        />
                    </button>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaSliderNewComponent implements OnInit, OnDestroy {
    @Input() sliderItems: CarouselSpotItem[] = [];
    @Input() autoPlay = true;
    @Input() autoPlayDelay = 20000;
    @Input() lazyLoad = false;
    @Input() preLoadRTEImages = false;
    @Output() indexChange = new EventEmitter<number>();
    activeIndex = 0;
    activateSlider = false;
    isBrowser: boolean;

    setAutoPlay: boolean | AutoplayOptions = false;
    autoPlayOptions: AutoplayOptions;
    lazyOptions: LazyOptions = {
        loadOnTransitionStart: true,
        loadPrevNext: false,
        checkInView: true,
    };
    mediaPreloadedResource?: HTMLLinkElement;
    vimeoPreloadedResource?: HTMLLinkElement;
    vimeoPreconnectResource?: HTMLLinkElement;
    videoFallbackImagePreloadedResource?: HTMLLinkElement;

    isImage = isImage;

    imageProcessorOptions: ImageProcessorOptions = {
        format: 'webp',
        height: 67,
        rmode: 'crop',
        width: 100,
    }

    constructor(
        public mediaSliderService: MediaSliderService,
        private zone: NgZone,
        private featureDetectionService: FeatureDetectionService,
        private readonly metaService: MetaService
    ) {
        this.isBrowser = this.featureDetectionService.isBrowser();
    }

    ngOnInit() {
        // Set optional configuration objects for Swiper. Must be done in ngOnInit()
        this.autoPlayOptions  = {
            delay: this.autoPlayDelay,
            disableOnInteraction: true,
        };
        this.setAutoPlay = this.autoPlay ? this.autoPlayOptions : false;

        const [firstItem] = this.sliderItems;

        if (this.isBrowser) {
            this.activateSlider = this.sliderItems.length > 1;

            // Preload media item: image or video
            if (firstItem.media) {
                // https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types/preload
                // "video preloading is included in the Preload spec, but is not currently implemented by browsers."
                // But once it is, this should improve performance

                const srcUrl = firstItem.media?.url ?? firstItem.media;

                this.mediaPreloadedResource = this.metaService.setPreloadResource(`${srcUrl}`, mediaIsImage(srcUrl) ? 'image' : 'video');
            } else if (firstItem.vimeoVideoID) {
                const vimeoSrcUrl = firstItem.vimeoVideoID;

                // Set Preload link for vimeo video url
                this.vimeoPreloadedResource = this.metaService.setPreloadResource(`https://player.vimeo.com/video/${vimeoSrcUrl}`, 'video');

                // Set Preconnect link for vimeo server
                this.vimeoPreconnectResource = this.metaService.setPreconnectResource('https://player.vimeo.com');
            }

            if (firstItem.videoFallbackImage?.url) {
                this.videoFallbackImagePreloadedResource = this.metaService.setPreloadResource(`${firstItem.videoFallbackImage?.url}?format=webp`, 'image');
            }
        }
    }

    ngOnDestroy(): void {
        this.mediaPreloadedResource?.remove();
        this.vimeoPreloadedResource?.remove();
        this.vimeoPreconnectResource?.remove();
        this.videoFallbackImagePreloadedResource?.remove();
    }

    onSlideChange(swiper: any) {
        // Looks like this event handler runs outside angular zone which
        // means changes won't be detected as usual.
        // I consider this a bug in swiper...
        // The following will force it to be run inside angular zone.
        this.zone.run(() => {
            this.setActiveIndex(swiper.realIndex);
            this.indexChange.emit(swiper.realIndex);
        });
    }

    setActiveIndex(index: number) {
        this.activeIndex = index;
    }
}
