<div
    class="mega-navigation-spot-layout__spot"
    [class.has-background-image]="hasImage"
    [style.background-image]="
        hasImage
            ? (data.image.src
              | resolveMediaUrl: { format: 'webp', width: 400, height: 400 }
              | asCssImageUrl)
            : undefined
    "
>
    <app-link
        *ngIf="hasLink"
        [link]="data.link"
        [classString]="'mega-navigation-spot-layout__spot-content'"
        [showLinkText]="false"
    >
        <ng-container *ngTemplateOutlet="content; context: { $implicit: data }"></ng-container>
        <div class="button--primary">{{ data.link.name }}</div>
    </app-link>

    <div class="mega-navigation-spot-layout__spot-content" *ngIf="!hasLink">
        <ng-container *ngTemplateOutlet="content; context: { $implicit: data }"></ng-container>
    </div>
</div>

<ng-template #content let-data>
    <div class="text-content" *ngIf="data.headline || data.categoryHeadline || data.text">
        <h2
            class="category-headline"
            *ngIf="data.categoryHeadline"
            [innerText]="data.categoryHeadline"
        ></h2>
        <h1 class="headline" *ngIf="data.headline" [innerText]="data.headline"></h1>
        <p class="text" *ngIf="data.text" [innerText]="data.text"></p>
    </div>
</ng-template>
