import { Location } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AppHttpErrorResponse, Cms, PAGE_TYPES } from '@impact/data';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response as ExpressResponse } from 'express';
import { EMPTY, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ContentService } from '../content/content.service';
import { SettingsService } from '../core/settings.service';

const invalidUrlCharacters = /[&\\#,+()$~%.'":*?<>{}]/g;

function isLinkPage(page: { type: string }): page is Cms.LinkPage {
    return page.type === PAGE_TYPES.LINK_PAGE;
}

function isRedirect(page: { type: string }): page is Cms.Redirect {
    return page.type === 'redirect';
}

@Injectable()
export class PageResolve implements Resolve<Cms.PageBase> {
    constructor(
        private contentService: ContentService,
        private location: Location,
        private router: Router,
        private settingsService: SettingsService,

        @Optional()
        @Inject(RESPONSE)
        private response?: ExpressResponse
    ) {}

    /**
     * Resolve a page from the given ActivatedRouteSnapshot.
     *
     * Resolves a page from the given ActivatedRouteSnapshot. If the resolved page is a link page,
     * it redirects to the linked URL or returns a not found page if the URL is missing. Handles
     * 404 and server errors by returning the appropriate error pages.
     *
     * @param route The ActivatedRouteSnapshot to resolve.
     * @returns An observable that emits the resolved page or an error page.
     */
    resolve(route: ActivatedRouteSnapshot) {
        const url = '/' + route.url.join('/');
        const urlIsMalformed = this.isUrlMalformed(url);

        if (urlIsMalformed) {
            console.warn('[PageResolve] Encountered malformed URL:', url);
            return this.notFoundPage();
        }

        const urlWithParams = this.router
            .createUrlTree([], {
                queryParams: route.queryParams,
            })
            .toString();

        const [, queryString] = urlWithParams.split('?');

        const normalizedUrl = url
            .toLowerCase()
            .replace(invalidUrlCharacters, '');

        const path = queryString
            ? normalizedUrl + '?' + encodeURIComponent(queryString)
            : normalizedUrl;

        return this.settingsService.getGlobalPages().pipe(
            map(pages => this.resolveUrl(path, pages)),
            switchMap(url => this.resolvePage(url)),
            take(1)
        );
    }

    /**
     * Resolves a page from the provided URL.
     *
     * This function retrieves the page data from the content service using the given URL.
     * If the retrieved page is a link page, it redirects to the linked URL or returns a
     * not found page if the URL is missing. Logs the resolved page for debugging purposes.
     * Handles 404 and server errors by returning the appropriate error pages.
     *
     * @param url - The URL of the page to resolve.
     * @returns An observable that emits the resolved page or an error page.
     */
    resolvePage(url: string) {
        return this.contentService.getPage(url).pipe(
            switchMap((page) => {
                if (isRedirect(page)) {
                    return this.redirectResponse(
                        page.targetUrl,
                        page.isPermanent,
                        false
                    );
                }

                if (isLinkPage(page)) {
                    const url = page.pageLink?.url;
                    return url
                        ? this.redirectResponse(url, true, true)
                        : this.notFoundPage();
                }

                return of(page);
            }),
            // Handle 404 and server errors
            catchError((err: AppHttpErrorResponse | undefined) => {
                return err?.error?.status === 404
                    ? this.notFoundPage()
                    : this.errorPage();
            })
        );
    }

    /**
     * Maps a URL to a page type.
     *
     * If the URL is a car details page, it is mapped to the usedCarsDetailsPage.
     * Otherwise, the URL is left as-is.
     *
     * @param url The URL to map.
     * @param globalPages The global pages configuration.
     * @returns The resolved URL.
     */
    resolveUrl(url: string, globalPages: Cms.GlobalPages): string {
        const overviewPageUrl = globalPages?.usedCarsOverviewPage?.url;
        const b2bOverviewPageUrl = globalPages?.b2bUsedCarsOverviewPage?.url;
        const isPDPregex = new RegExp(
            `^(${overviewPageUrl}|${b2bOverviewPageUrl})/\\d+($|\\?|%3F)`
        );

        if (isPDPregex.test(url)) {
            return globalPages.usedCarsDetailsPage.url;
        }

        return url;
    }

    /**
     * Sets the response status to SERVER_ERROR and headers for Cache-Control and max-age.
     * 
     * @returns An observable that emits an object with type PAGE_TYPES.ERROR_PAGE and metaTitle 'Error - something went wrong'.
     */
    private errorPage() {
        this.response?.status(500);
        this.response?.setHeader('Cache-Control', 'no-cache');
        this.response?.setHeader('max-age', '-5000');

        return of({
            type: PAGE_TYPES.ERROR_PAGE,
            metaTitle: 'Error - something went wrong',
        });
    }

    /**
     * Detect malformed urls to make sure that we do not send malformed "bilmagasin"
     * requests to the BFF. Can be removed if we figure out where these malformed urls
     * are coming from, and are eliminated.
     *
     * Example path: `/bilmagasin%23/foo`
     *
     * Example path: `/bilmagasin%3Fcategory/bar`
     */
    private isUrlMalformed(url: string) {
        const [, rootSegment] = url.split('/');

        /** If the root segment contains a hash character, it is malformed */
        if (rootSegment?.includes('#') || rootSegment?.includes('%23'))
            return true;

        /**
         * If the root segment contains a question mark character, and the complete URL
         * *does not* provide a query string value, it is malformed.
         */
        if (rootSegment?.includes('?') || rootSegment?.includes('%3f'))
            return !url.split('=')[1];

        return false;
    }

    /**
     * Retrieves the not found page URL from the settings, fetches the content of the not found page,
     * and returns an observable that emits the content of the not found page. If the not found page URL is missing,
     * it throws an error with the message 'No not found page'. In case of an error during the process,
     * it returns the error page.
     *
     * @returns An observable that emits the content of the not found page or the error page.
     */
    private notFoundPage() {
        return this.settingsService.get().pipe(
            take(1),
            switchMap((settings) => {
                this.response?.status(404);

                const notFoundUrl = settings.globalPages?.notFoundPage?.url;

                if (!notFoundUrl) {
                    return throwError(new Error('No not found page'));
                }

                return this.contentService.getPage<any>(notFoundUrl);
            }),
            catchError(() => {
                return this.errorPage();
            })
        );
    }

    /**
     * Performs a redirect to `targetUrl`.
     *
     * If `targetUrl` is a relative URL, it is resolved relative to the current request.
     * If `targetUrl` is an absolute URL, it is used as is.
     *
     * If a query string is present in the current request, it is appended to `targetUrl` if it doesn't already have a query string.
     *
     * If the response object is present, a 301 redirect is sent.
     * Otherwise, the redirect is performed on the client side using the Angular router or by setting `window.location.href`.
     *
     * @param targetUrl The URL to redirect to.
     *
     * @returns An empty observable.
     */
    private redirectResponse(targetUrl: string, isPermanent: boolean, preserveQueryString: boolean) {
        const isAbsoluteUrl = targetUrl.startsWith('http');

        /**
         * If query strings should be preserved and `targetUrl` does not include a query
         * string, append the query strings from the current request.
         */
        if (preserveQueryString && !targetUrl.includes('?')) {
            const [, queryString] = this.location.path().split('?');
            targetUrl = queryString ? targetUrl + '?' + queryString : targetUrl;
        }

        if (this.response) {
            if (!isAbsoluteUrl) {
                const { headers, protocol } = this.response.req;
                targetUrl = `${protocol}://${headers.host}${targetUrl}`;
            }

            this.response.redirect(isPermanent ? 301 : 302, targetUrl);
            this.response.end();
        } else {
            if (isAbsoluteUrl) {
                window.location.href = targetUrl;
            } else {
                this.router.navigateByUrl(targetUrl);
            }
        }

        return EMPTY;
    }
}
