export type EnvironmentSite =
    | 'Bjarne Nielsen'
    | 'Bilernes Hus'
    | 'Leasing'
    | 'Motorpoint';

export interface Environment {
    cmsUrl: string;
    environmentKey: string;
    mediaUrl: string;
    site: EnvironmentSite;
}

interface HostEnvironment extends Environment {
    hostnames: string[];
}

export function resolveEnvironment(
    currentHostname: string,
    devEnvironmentHostname?: string
): Environment {
    const hostname =
        devEnvironmentHostname && currentHostname === 'localhost'
            ? devEnvironmentHostname
            : currentHostname;

    const environment = environments.find((e) =>
        e.hostnames.includes(hostname)
    );

    if (!environment)
        throw new Error(
            `[resolveEnvironment] Unable to resolve enviroment for hostname "${hostname}"`
        );

    return environment;
}

// prettier-ignore
const environments: HostEnvironment[] = [
    {
        cmsUrl: 'https://dev-content.bn.dk/api/v1',
        environmentKey: '3b9f0668-bced-42ff-bbc8-cd602a7438ba',
        hostnames: [
            'dev.bn.dk',
            'dev-bn.dk',
            'www.dev-bn.dk',
        ],
        mediaUrl: 'https://dev-bjarne-nielsen-a-s.euwest01.umbraco.io',
        site: 'Bjarne Nielsen',
    },
    {
        cmsUrl: 'https://staging-content.bn.dk/api/v1',
        environmentKey: '3b9f0668-bced-42ff-bbc8-cd602a7438ba',
        hostnames: [
            'staging.bn.dk',
            'staging-bn.dk',
            'www.staging-bn.dk',
        ],
        mediaUrl: 'https://stage-bjarne-nielsen-a-s.euwest01.umbraco.io',
        site: 'Bjarne Nielsen',
    },
    {
        cmsUrl: 'https://content.bn.dk/api/v1',
        environmentKey: '3b9f0668-bced-42ff-bbc8-cd602a7438ba',
        hostnames: [
            'bn.dk',
            'www.bn.dk',
            'preprod-bn.dk',
            'www.preprod-bn.dk',
        ],
        mediaUrl: 'https://media.bn.dk',
        site: 'Bjarne Nielsen',
    },
    {
        cmsUrl: 'https://dev-content.bn.dk/api/v1',
        environmentKey: 'bf3fd6b8-57f3-44d1-a982-463e60d0a72a',
        hostnames: [
            'dev.bilerneshus.dk',
            'dev-bilerneshus.dk',
        ],
        mediaUrl: 'https://dev-bjarne-nielsen-a-s.euwest01.umbraco.io',
        site: 'Bilernes Hus',
    },
    {
        cmsUrl: 'https://staging-content.bn.dk/api/v1',
        environmentKey: 'bf3fd6b8-57f3-44d1-a982-463e60d0a72a',
        hostnames: [
            'staging.bilerneshus.dk',
            'staging-bilerneshus.dk',
            'www.staging-bilerneshus.dk',
        ],
        mediaUrl: 'https://stage-bjarne-nielsen-a-s.euwest01.umbraco.io',
        site: 'Bilernes Hus',
    },
    {
        cmsUrl: 'https://content.bn.dk/api/v1',
        environmentKey: 'bf3fd6b8-57f3-44d1-a982-463e60d0a72a',
        hostnames: [
            'bilerneshus.dk',
            'www.bilerneshus.dk',
            'preprod-bilerneshus.dk',
            'www.preprod-bilerneshus.dk',
        ],
        mediaUrl: 'https://media.bilerneshus.dk',
        site: 'Bilernes Hus',
    },
    {
        cmsUrl: 'https://dev-content.bn.dk/api/v1',
        environmentKey: 'd04b224a-f369-415a-b89b-5b006115f113',
        hostnames: [
            'dev.leasing.dk',
        ],
        mediaUrl: 'https://dev-bjarne-nielsen-a-s.euwest01.umbraco.io',
        site: 'Leasing',
    },
    {
        cmsUrl: 'https://staging-content.bn.dk/api/v1',
        environmentKey: 'd04b224a-f369-415a-b89b-5b006115f113',
        hostnames: [
            'staging.leasing.dk',
            'www.staging.leasing.dk',
        ],
        mediaUrl: 'https://stage-bjarne-nielsen-a-s.euwest01.umbraco.io',
        site: 'Leasing',
    },
    {
        cmsUrl: 'https://content.bn.dk/api/v1',
        environmentKey: 'd04b224a-f369-415a-b89b-5b006115f113',
        hostnames: [
            'leasing.dk',
            'www.leasing.dk',
            'preprod-leasing.dk',
            'www.preprod-leasing.dk',
        ],
        mediaUrl: 'https://media.leasing.dk',
        site: 'Leasing',
    },
    {
        cmsUrl: 'https://dev-content.bn.dk/api/v1',
        environmentKey: 'acf0214d-d35f-4cc8-8bee-625732053d4b',
        hostnames: [
            'dev.motorpoint.dk',
        ],
        mediaUrl: 'https://dev-bjarne-nielsen-a-s.euwest01.umbraco.io',
        site: 'Motorpoint',
    },
    {
        cmsUrl: 'https://staging-content.bn.dk/api/v1',
        environmentKey: 'acf0214d-d35f-4cc8-8bee-625732053d4b',
        hostnames: [
            'staging.motorpoint.dk',
            'www.staging.motorpoint.dk',
        ],
        mediaUrl: 'https://stage-bjarne-nielsen-a-s.euwest01.umbraco.io',
        site: 'Motorpoint',
    },
    {
        cmsUrl: 'https://content.bn.dk/api/v1',
        environmentKey: 'acf0214d-d35f-4cc8-8bee-625732053d4b',
        hostnames: [
            'motorpoint.dk',
            'www.motorpoint.dk',
            'preprod.motorpoint.dk',
        ],
        mediaUrl: 'https://media.motorpoint.dk',
        site: 'Motorpoint',
    },
];
