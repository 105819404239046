import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LinkModule } from '../../link/link.module';
import { UtilsModule } from '../../utils/utils.module';
import { IsValidNavigationSpotPipe } from './is-valid-navigation-spot.pipe';
import { MegaNavigationSpotComponent } from './mega-navigation-spot.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule.forChild(),
        LinkModule,
        UtilsModule,
    ],
    exports: [IsValidNavigationSpotPipe, MegaNavigationSpotComponent],
    declarations: [IsValidNavigationSpotPipe, MegaNavigationSpotComponent],
    providers: [],
})
export class MegaNavigationSpotModule {}
